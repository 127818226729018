<template>
  <div class="script">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectIndex: 1,
      list: [],
    };
  },
  methods: {},
};
</script>


<style lang="less" scoped>
.script {
  height: 100%;
}
</style>